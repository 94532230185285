import { Suspense } from 'react';
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import { useSnackbar } from '@uniqkey-frontend/shared-app';
import PageRouteEnum from './enums/PageRouteEnum';
import ACLEnum from './enums/ACLEnum';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import LoginPage from './pages/LoginPage';
// import SetupPermanentPasswordPage from './pages/SetupPermanentPasswordPage';
import OrganizationsPage from './pages/OrganizationsPage';
import { useUser } from './contexts/UserContext';
import OrganizationPage from './pages/OrganizationPage';
import OrganizationModule, {
  OrganizationModuleTabEnum,
} from './pages/OrganizationPage/components/OrganizationModule';
import PartnersPage from './pages/PartnersPage';
import PartnerPage from './pages/PartnerPage';
import PartnerModule, {
  PartnerModuleTabEnum,
} from './pages/PartnerPage/components/PartnerModule';
import PartnerUsersPage from './pages/PartnerUsersPage';
import PartnerUserPage from './pages/PartnerUserPage';
import PartnerUserModule, {
  PartnerUserModuleTabEnum,
} from './pages/PartnerUserPage/components/PartnerUserModule';
import AuditLogsModule, {
  AuditLogsModuleTabEnum,
} from './pages/AuditLogsPage/components/AuditLogsModule';
import AuditLogsPage from './pages/AuditLogsPage';
import SetupMasterPasswordPage from './pages/SetupMasterPasswordPage';
import SetupTwoFAPage from './pages/SetupTwoFAPage';
import LoginTwoFAPage from './pages/LoginTwoFAPage';
import ProfilePage from './pages/ProfilePage';
import OrganizationEmployeePage from './pages/OrganizationEmployeePage';
import OrganizationEmployeeModule, {
  OrganizationEmployeeModuleTabEnum,
} from './pages/OrganizationEmployeePage/components/OrganizationEmployeeModule';
import RolesPage from './pages/RolesPage';
import RolePage from './pages/RolePage';
import PartnerPartnerUserPage from './pages/PartnerPartnerUserPage';
import PartnerPartnerUserModule, {
  PartnerPartnerUserModuleTabEnum,
} from './pages/PartnerPartnerUserPage/components/PartnerPartnerUserModule';
import PartnerPartnerPage from './pages/PartnerPartnerPage';
import PartnerPartnerModule, {
  PartnerPartnerModuleTabEnum,
} from './pages/PartnerPartnerPage/components/PartnerPartnerModule';
import PartnerOrganizationPage from './pages/PartnerOrganizationPage';
import PartnerOrganizationModule, {
  PartnerOrganizationModuleTabEnum,
} from './pages/PartnerOrganizationPage/components/PartnerOrganizationModule';
import PartnerOrganizationEmployeePage from './pages/PartnerOrganizationEmployeePage';
import PartnerOrganizationEmployeeModule, {
  PartnerOrganizationEmployeeModuleTabEnum,
} from './pages/PartnerOrganizationEmployeePage/components/PartnerOrganizationEmployeeModule';
import PartnerUsersForAdminPage from './pages/PartnerUsersForAdminPage';
import GroupsPage from './pages/GroupsPage';
import GroupPage from './pages/GroupPage';
import GroupModule, { GroupModuleTabEnum } from './pages/GroupPage/components/GroupModule';
import GroupOrganizationPage from './pages/GroupOrganizationPage';
import GroupOrganizationModule, {
  GroupOrganizationModuleTabEnum,
} from './pages/GroupOrganizationPage/components/GroupOrganizationModule';
import GroupOrganizationEmployeePage from './pages/GroupOrganizationEmployeePage';
import GroupOrganizationEmployeeModule, {
  GroupOrganizationEmployeeModuleTabEnum,
} from './pages/GroupOrganizationEmployeePage/components/GroupOrganizationEmployeeModule';
import { isAdmin } from './helpers/userRole';
import OrganizationsExtendedPage from './pages/OrganizationsExtendedPage';

const ROUTER_FUTURE_CONFIG = { future: { v7_startTransition: true } }; // TODO: NEXTGEN-6652

const Router = () => {
  const {
    isAuthenticated,
    isCurrentUserLoading,
    masterPasswordSet,
    twoFactorSet,
    loginVerified,
    stateChanged,
    areACLsLoading,
    userCan,
    currentUser,
  } = useUser();

  const { role } = currentUser ?? {};

  const { getSnackbar } = useSnackbar();

  if (isCurrentUserLoading || (loginVerified && areACLsLoading)) {
    /*
     Show suspense if:
       current user isn't loaded;
       or the current user is loaded, but ACLs aren't loaded yet.
    */
    return <Suspense fallback={null} />;
  }

  const canViewOrganizations = userCan(ACLEnum.OrganizationView);
  const defaultAuthorizedRoute = canViewOrganizations
    ? PageRouteEnum.Organizations
    : PageRouteEnum.Profile;

  return (
    <BrowserRouter {...ROUTER_FUTURE_CONFIG}>
      <Routes>
        {/* <Route
        path={PageRouteEnum.SetupPermanentPassword}
        element={<UnauthorizedLayout
        ><SetupPermanentPasswordPage /></UnauthorizedLayout>} /> */}
        <Route element={(
          <UnauthenticatedRoute
            isAuthenticated={isAuthenticated}
            masterPasswordSet={masterPasswordSet}
            twoFactorSet={twoFactorSet}
            loginVerified={loginVerified}
            stateChanged={stateChanged}
            defaultAuthorizedRoute={defaultAuthorizedRoute}
          />
        )}
        >
          <Route path={PageRouteEnum.Login} element={<LoginPage />} />
          <Route path={PageRouteEnum.SetupMasterPassword} element={<SetupMasterPasswordPage />} />
          <Route path={PageRouteEnum.SetupTwoFA} element={<SetupTwoFAPage />} />
          <Route path={PageRouteEnum.LoginTwoFA} element={<LoginTwoFAPage />} />
        </Route>
        <Route element={(
          <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            masterPasswordSet={masterPasswordSet}
            twoFactorSet={twoFactorSet}
            loginVerified={loginVerified}
            withLayout
          />
        )}
        >
          {userCan(ACLEnum.PartnerView) && (
            <Route path={PageRouteEnum.Partners}>
              <Route index element={<PartnersPage />} />
              <Route path={PageRouteEnum.Partner} element={<PartnerPage />}>
                <Route
                  index
                  element={<PartnerModule activeTab={PartnerModuleTabEnum.Details} />}
                />
                <Route
                  path={PartnerModuleTabEnum.Details}
                  element={<PartnerModule activeTab={PartnerModuleTabEnum.Details} />}
                />
                {userCan(ACLEnum.PartnerViewOrganizations) && (
                  <Route
                    path={PartnerModuleTabEnum.Organizations}
                    element={<PartnerModule activeTab={PartnerModuleTabEnum.Organizations} />}
                  />
                )}
                {userCan(ACLEnum.PartnerViewLicenseHistory) && (
                  <Route
                    path={PartnerModuleTabEnum.Licenses}
                    element={<PartnerModule activeTab={PartnerModuleTabEnum.Licenses} />}
                  />
                )}
                {userCan(ACLEnum.PartnerUserView) && (
                  <Route
                    path={PartnerModuleTabEnum.PartnerUsers}
                    element={<PartnerModule activeTab={PartnerModuleTabEnum.PartnerUsers} />}
                  />
                )}
                {userCan(ACLEnum.PartnerView) && (
                  <Route
                    path={PartnerModuleTabEnum.Partners}
                    element={<PartnerModule activeTab={PartnerModuleTabEnum.Partners} />}
                  />
                )}
                <Route
                  path={PartnerModuleTabEnum.Settings}
                  element={<PartnerModule activeTab={PartnerModuleTabEnum.Settings} />}
                />
                <Route
                  path="*"
                  element={<Navigate to={PartnerModuleTabEnum.Details} />}
                />
              </Route>
            </Route>
          )}
          {userCan(ACLEnum.PartnerUserView) && (
            <Route path={PageRouteEnum.PartnerPartnerUser} element={<PartnerPartnerUserPage />}>
              <Route
                index
                element={(
                  <PartnerPartnerUserModule activeTab={PartnerPartnerUserModuleTabEnum.Details} />
                )}
              />
              <Route
                path={PartnerPartnerUserModuleTabEnum.Details}
                element={(
                  <PartnerPartnerUserModule activeTab={PartnerPartnerUserModuleTabEnum.Details} />
                )}
              />
              <Route
                path="*"
                element={<Navigate to={PartnerPartnerUserModuleTabEnum.Details} />}
              />
            </Route>
          )}
          {userCan(ACLEnum.PartnerView) && (
            <Route
              path={PageRouteEnum.PartnerDeep}
              element={<PartnerPartnerPage />}
            >
              <Route
                index
                element={(
                  <PartnerPartnerModule activeTab={PartnerPartnerModuleTabEnum.Details} />
                )}
              />
              <Route
                path={PartnerPartnerModuleTabEnum.Details}
                element={(
                  <PartnerPartnerModule activeTab={PartnerPartnerModuleTabEnum.Details} />
                )}
              />
              {userCan(ACLEnum.PartnerViewOrganizations) && (
                <Route
                  path={PartnerPartnerModuleTabEnum.Organizations}
                  element={(
                    <PartnerPartnerModule activeTab={PartnerPartnerModuleTabEnum.Organizations} />
                  )}
                />
              )}
              {userCan(ACLEnum.PartnerViewLicenseHistory) && (
                <Route
                  path={PartnerPartnerModuleTabEnum.Licenses}
                  element={(
                    <PartnerPartnerModule activeTab={PartnerPartnerModuleTabEnum.Licenses} />
                  )}
                />
              )}
              {userCan(ACLEnum.PartnerUserView) && (
                <Route
                  path={PartnerPartnerModuleTabEnum.PartnerUsers}
                  element={(
                    <PartnerPartnerModule activeTab={PartnerPartnerModuleTabEnum.PartnerUsers} />
                  )}
                />
              )}
              {userCan(ACLEnum.PartnerView) && (
                <Route
                  path={PartnerPartnerModuleTabEnum.Partners}
                  element={(
                    <PartnerPartnerModule activeTab={PartnerPartnerModuleTabEnum.Partners} />
                  )}
                />
              )}
              <Route
                path={PartnerPartnerModuleTabEnum.Settings}
                element={(
                  <PartnerPartnerModule activeTab={PartnerPartnerModuleTabEnum.Settings} />
                )}
              />
              <Route
                path="*"
                element={<Navigate to={PartnerPartnerModuleTabEnum.Details} />}
              />
            </Route>
          )}
          {userCan(ACLEnum.PartnerView) && (
            <Route
              path={PageRouteEnum.PartnerOrganization}
              element={<PartnerOrganizationPage />}
            >
              <Route
                index
                element={(
                  <PartnerOrganizationModule
                    activeTab={PartnerOrganizationModuleTabEnum.Details}
                  />
                )}
              />
              <Route
                path={PartnerOrganizationModuleTabEnum.Details}
                element={(
                  <PartnerOrganizationModule
                    activeTab={PartnerOrganizationModuleTabEnum.Details}
                  />
                )}
              />
              {userCan(ACLEnum.OrganizationViewDomains) && (
                <Route
                  path={PartnerOrganizationModuleTabEnum.Domains}
                  element={(
                    <PartnerOrganizationModule
                      activeTab={PartnerOrganizationModuleTabEnum.Domains}
                    />
                  )}
                />
              )}
              {userCan(ACLEnum.OrganizationViewAdmins) && (
                <Route
                  path={PartnerOrganizationModuleTabEnum.Admins}
                  element={(
                    <PartnerOrganizationModule
                      activeTab={PartnerOrganizationModuleTabEnum.Admins}
                    />
                  )}
                />
              )}
              {userCan(ACLEnum.OrganizationViewLicenseHistory) && (
                <Route
                  path={PartnerOrganizationModuleTabEnum.Licenses}
                  element={(
                    <PartnerOrganizationModule
                      activeTab={PartnerOrganizationModuleTabEnum.Licenses}
                    />
                  )}
                />
              )}
              {userCan(ACLEnum.EmployeeAccountView) && (
                <Route
                  path={PartnerOrganizationModuleTabEnum.Employees}
                  element={(
                    <PartnerOrganizationModule
                      activeTab={PartnerOrganizationModuleTabEnum.Employees}
                    />
                  )}
                />
              )}
              <Route
                path="*"
                element={<Navigate to={PartnerOrganizationModuleTabEnum.Details} />}
              />
            </Route>
          )}
          {userCan(ACLEnum.EmployeeAccountView) && (
            <Route
              path={PageRouteEnum.PartnerOrganizationEmployee}
              element={<PartnerOrganizationEmployeePage />}
            >
              <Route
                index
                element={(
                  <PartnerOrganizationEmployeeModule
                    activeTab={PartnerOrganizationEmployeeModuleTabEnum.Details}
                  />
                )}
              />
              <Route
                path={PartnerOrganizationEmployeeModuleTabEnum.Details}
                element={(
                  <PartnerOrganizationEmployeeModule
                    activeTab={PartnerOrganizationEmployeeModuleTabEnum.Details}
                  />
                )}
              />
              {userCan(ACLEnum.EmployeeAccountViewVaultPasswords) && (
                <Route
                  path={PartnerOrganizationEmployeeModuleTabEnum.Logins}
                  element={(
                    <PartnerOrganizationEmployeeModule
                      activeTab={PartnerOrganizationEmployeeModuleTabEnum.Logins}
                    />
                  )}
                />
              )}
              {userCan(ACLEnum.EmployeeAccountViewAuditLogs) && (
                <Route
                  path={PartnerOrganizationEmployeeModuleTabEnum.AuditLogs}
                  element={(
                    <PartnerOrganizationEmployeeModule
                      activeTab={PartnerOrganizationEmployeeModuleTabEnum.AuditLogs}
                    />
                  )}
                />
              )}
              <Route
                path="*"
                element={<Navigate to={PartnerOrganizationEmployeeModuleTabEnum.Details} />}
              />
            </Route>
          )}
          {canViewOrganizations && (
            <Route path={PageRouteEnum.Organizations}>
              <Route
                index
                element={(
                  userCan(ACLEnum.OrganizationExtendedView)
                    ? <OrganizationsExtendedPage />
                    : <OrganizationsPage />
                )}
              />
              <Route path={PageRouteEnum.Organization} element={<OrganizationPage />}>
                <Route
                  index
                  element={<OrganizationModule activeTab={OrganizationModuleTabEnum.Details} />}
                />
                <Route
                  path={OrganizationModuleTabEnum.Details}
                  element={<OrganizationModule activeTab={OrganizationModuleTabEnum.Details} />}
                />
                {userCan(ACLEnum.OrganizationViewDomains) && (
                  <Route
                    path={OrganizationModuleTabEnum.Domains}
                    element={<OrganizationModule activeTab={OrganizationModuleTabEnum.Domains} />}
                  />
                )}
                {userCan(ACLEnum.OrganizationViewAdmins) && (
                  <Route
                    path={OrganizationModuleTabEnum.Admins}
                    element={<OrganizationModule activeTab={OrganizationModuleTabEnum.Admins} />}
                  />
                )}
                {userCan(ACLEnum.OrganizationViewLicenseHistory) && (
                  <Route
                    path={OrganizationModuleTabEnum.Licenses}
                    element={<OrganizationModule activeTab={OrganizationModuleTabEnum.Licenses} />}
                  />
                )}
                {userCan(ACLEnum.EmployeeAccountView) && (
                  <Route
                    path={OrganizationModuleTabEnum.Employees}
                    element={
                      <OrganizationModule activeTab={OrganizationModuleTabEnum.Employees} />
                    }
                  />
                )}
                {userCan(ACLEnum.OrganizationViewAuditLogs) && (
                  <Route
                    path={OrganizationModuleTabEnum.AuditLogs}
                    element={
                      <OrganizationModule activeTab={OrganizationModuleTabEnum.AuditLogs} />
                    }
                  />
                )}
                <Route
                  path="*"
                  element={<Navigate to={OrganizationModuleTabEnum.Details} />}
                />
              </Route>
            </Route>
          )}
          {userCan(ACLEnum.EmployeeAccountView) && (
            <Route path={PageRouteEnum.OrganizationEmployee} element={<OrganizationEmployeePage />}>
              <Route
                index
                element={(
                  <OrganizationEmployeeModule
                    activeTab={OrganizationEmployeeModuleTabEnum.Details}
                  />
                )}
              />
              <Route
                path={OrganizationEmployeeModuleTabEnum.Details}
                element={(
                  <OrganizationEmployeeModule
                    activeTab={OrganizationEmployeeModuleTabEnum.Details}
                  />
                )}
              />
              {userCan(ACLEnum.EmployeeAccountViewVaultPasswords) && (
                <Route
                  path={OrganizationEmployeeModuleTabEnum.Logins}
                  element={(
                    <OrganizationEmployeeModule
                      activeTab={OrganizationEmployeeModuleTabEnum.Logins}
                    />
                  )}
                />
              )}
              {userCan(ACLEnum.EmployeeAccountViewAuditLogs) && (
                <Route
                  path={OrganizationEmployeeModuleTabEnum.AuditLogs}
                  element={(
                    <OrganizationEmployeeModule
                      activeTab={OrganizationEmployeeModuleTabEnum.AuditLogs}
                    />
                  )}
                />
              )}
              <Route
                path="*"
                element={<Navigate to={OrganizationEmployeeModuleTabEnum.Details} />}
              />
            </Route>
          )}
          {userCan(ACLEnum.PartnerUserView) && (
            <Route path={PageRouteEnum.PartnerUsers}>
              <Route
                index
                element={isAdmin(role) ? <PartnerUsersForAdminPage /> : <PartnerUsersPage />}
              />
              <Route path={PageRouteEnum.PartnerUser} element={<PartnerUserPage />}>
                <Route
                  index
                  element={<PartnerUserModule activeTab={PartnerUserModuleTabEnum.Details} />}
                />
                <Route
                  path={PartnerUserModuleTabEnum.Details}
                  element={<PartnerUserModule activeTab={PartnerUserModuleTabEnum.Details} />}
                />
                <Route
                  path="*"
                  element={<Navigate to={PartnerUserModuleTabEnum.Details} />}
                />
              </Route>
            </Route>
          )}
          {userCan(ACLEnum.GroupView) && (
            <Route path={PageRouteEnum.Groups}>
              <Route index element={<GroupsPage />} />
              <Route path={PageRouteEnum.Group} element={<GroupPage />}>
                <Route
                  index
                  element={<GroupModule activeTab={GroupModuleTabEnum.Details} />}
                />
                <Route
                  path={GroupModuleTabEnum.Details}
                  element={<GroupModule activeTab={GroupModuleTabEnum.Details} />}
                />
                <Route
                  path={GroupModuleTabEnum.Users}
                  element={<GroupModule activeTab={GroupModuleTabEnum.Users} />}
                />
                <Route
                  path={GroupModuleTabEnum.Organizations}
                  element={<GroupModule activeTab={GroupModuleTabEnum.Organizations} />}
                />
                <Route
                  path="*"
                  element={<Navigate to={GroupModuleTabEnum.Details} />}
                />
              </Route>
            </Route>
          )}
          {userCan(ACLEnum.GroupView) && (
            <Route
              path={PageRouteEnum.GroupOrganization}
              element={<GroupOrganizationPage />}
            >
              <Route
                index
                element={(
                  <GroupOrganizationModule
                    activeTab={GroupOrganizationModuleTabEnum.Details}
                  />
                )}
              />
              <Route
                path={GroupOrganizationModuleTabEnum.Details}
                element={(
                  <GroupOrganizationModule
                    activeTab={GroupOrganizationModuleTabEnum.Details}
                  />
                )}
              />
              {userCan(ACLEnum.OrganizationViewDomains) && (
                <Route
                  path={GroupOrganizationModuleTabEnum.Domains}
                  element={(
                    <GroupOrganizationModule
                      activeTab={GroupOrganizationModuleTabEnum.Domains}
                    />
                  )}
                />
              )}
              {userCan(ACLEnum.OrganizationViewAdmins) && (
                <Route
                  path={GroupOrganizationModuleTabEnum.Admins}
                  element={(
                    <GroupOrganizationModule
                      activeTab={GroupOrganizationModuleTabEnum.Admins}
                    />
                  )}
                />
              )}
              {userCan(ACLEnum.OrganizationViewLicenseHistory) && (
                <Route
                  path={GroupOrganizationModuleTabEnum.Licenses}
                  element={(
                    <GroupOrganizationModule
                      activeTab={GroupOrganizationModuleTabEnum.Licenses}
                    />
                  )}
                />
              )}
              {userCan(ACLEnum.EmployeeAccountView) && (
                <Route
                  path={GroupOrganizationModuleTabEnum.Employees}
                  element={(
                    <GroupOrganizationModule
                      activeTab={GroupOrganizationModuleTabEnum.Employees}
                    />
                  )}
                />
              )}
              {userCan(ACLEnum.OrganizationViewAuditLogs) && (
                <Route
                  path={GroupOrganizationModuleTabEnum.AuditLogs}
                  element={(
                    <GroupOrganizationModule
                      activeTab={GroupOrganizationModuleTabEnum.AuditLogs}
                    />
                  )}
                />
              )}
              <Route
                path="*"
                element={<Navigate to={GroupOrganizationModuleTabEnum.Details} />}
              />
            </Route>
          )}
          {userCan(ACLEnum.EmployeeAccountView) && (
            <Route
              path={PageRouteEnum.GroupOrganizationEmployee}
              element={<GroupOrganizationEmployeePage />}
            >
              <Route
                index
                element={(
                  <GroupOrganizationEmployeeModule
                    activeTab={GroupOrganizationEmployeeModuleTabEnum.Details}
                  />
                )}
              />
              <Route
                path={GroupOrganizationEmployeeModuleTabEnum.Details}
                element={(
                  <GroupOrganizationEmployeeModule
                    activeTab={GroupOrganizationEmployeeModuleTabEnum.Details}
                  />
                )}
              />
              {userCan(ACLEnum.EmployeeAccountViewVaultPasswords) && (
                <Route
                  path={GroupOrganizationEmployeeModuleTabEnum.Logins}
                  element={(
                    <GroupOrganizationEmployeeModule
                      activeTab={GroupOrganizationEmployeeModuleTabEnum.Logins}
                    />
                  )}
                />
              )}
              {userCan(ACLEnum.EmployeeAccountViewAuditLogs) && (
                <Route
                  path={GroupOrganizationEmployeeModuleTabEnum.AuditLogs}
                  element={(
                    <GroupOrganizationEmployeeModule
                      activeTab={GroupOrganizationEmployeeModuleTabEnum.AuditLogs}
                    />
                  )}
                />
              )}
              <Route
                path="*"
                element={<Navigate to={GroupOrganizationEmployeeModuleTabEnum.Details} />}
              />
            </Route>
          )}
          {userCan(ACLEnum.AuditLogView) && (
            <Route path={PageRouteEnum.Audit} element={<AuditLogsPage />}>
              <Route
                index
                element={<Navigate to={AuditLogsModuleTabEnum.AuditLogs} />}
              />
              <Route
                path={AuditLogsModuleTabEnum.AuditLogs}
                element={<AuditLogsModule activeTab={AuditLogsModuleTabEnum.AuditLogs} />}
              />
              <Route
                path="*"
                element={<Navigate to={AuditLogsModuleTabEnum.AuditLogs} />}
              />
            </Route>
          )}
          {userCan(ACLEnum.PermissionView) && (
            <Route path={PageRouteEnum.Roles}>
              <Route index element={<RolesPage />} />
              <Route path={PageRouteEnum.Role} element={<RolePage />} />
            </Route>
          )}
          <Route path={PageRouteEnum.Profile} element={<ProfilePage />} />
        </Route>
        <Route
          path="*"
          element={(
            <Navigate
              to={isAuthenticated && loginVerified ? defaultAuthorizedRoute : PageRouteEnum.Login}
            />
          )}
        />
      </Routes>
      {getSnackbar()}
    </BrowserRouter>
  );
};

export default Router;
